import React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'rebass';
import {Link, useRouteMatch} from 'react-router-dom';

const TabbedNavButton = styled(Box)`
    position: relative;
    font-size: 14px;
    color: #000;
   
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
    & a{
        color:#000;
        text-decoration: none;
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    &.toggled:after{
        content:"";
        height:5px; 
        width:100%;
        background:#000;
        position:absolute;
        bottom:0;
        left:0;
    }
`;

export default function(props){

    let {path, url} = useRouteMatch();

    const expirationTime = 140; //hours

    //Calculate filter so we can show the fresh content first for both featured and popular

    const changeFilter = (parameter)=>{
 
        let filter = {order:"desc", order_by:"date"};

        if(parameter == "featured"){
            filter = {order:"desc", order_by:"date", label:"featured"};
        }else if(parameter == "views"){
            filter = {order:"desc", order_by:"post_views_count"};
        }

        props.setFilters(filter);
        props.setPosts([]);
        props.setPage(1);
    };



    return(<Flex justifyContent="space-around" alignItems="center" marginBottom="1rem" style={{color:"black", height:"45px"}}>
    <TabbedNavButton onClick={e=>{changeFilter("date")}} className={(window.location.pathname == "/nuevo") ? "toggled" : null}><Link to="/nuevo">NUEVO</Link></TabbedNavButton>
    <TabbedNavButton  onClick={e=>{changeFilter("featured")}} className={(window.location.pathname == "/"  || window.location.pathname == "/curado") ? "toggled" : null}><Link to="/curado">CURADO</Link></TabbedNavButton>
    <TabbedNavButton  onClick={e=>{changeFilter("views")}} className={(window.location.pathname == "/popular") ? "toggled" : null}><Link to="/popular">POPULAR</Link></TabbedNavButton>
</Flex>);
}


