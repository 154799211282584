import './RelatedIdea.css';
import React from 'react';
import lightIcon from '../../images/lights_icon.svg';
import {useHistory} from 'react-router-dom';

function RelatedIdea(props) {

    let history = useHistory();

    

    if(props.post === null || typeof(props.post) === "undefined"){return null};

    let post = props.post;
    if(post.status !== "publish" || post === null){return null}

     let title = post.title.rendered;
    // let link = post.link;
     let excerpt = post.excerpt.rendered;
     let author = post._embedded.author[0].name;
     let authorLink = post._embedded.author[0].link;
     let image = "";
     if(post._embedded["wp:featuredmedia"]){
        image = post._embedded["wp:featuredmedia"][0].media_details.sizes.full.source_url;
     }
     
     let categoryColor = post._embedded["wp:term"][0][0].link.split("category/")[1].split("/")[0];
     let categoryName = post._embedded["wp:term"][0][0].name;
     let categoryLink = post._embedded["wp:term"][0][0].link;

     let lightsCount = post.meta['post_views_count'][0];

     let textarea = document.createElement("textarea");
     textarea.innerHTML = title;
     title = textarea.value;
     textarea.innerHTML = excerpt;
     excerpt = textarea.value;
     textarea.innerHTML = categoryName;
     categoryName = textarea.value;
     

     return (<div className={"related-idea post one-third column "+categoryColor+" "+props.className} style={{backgroundImage:`url('${image}')`}} onClick={()=>{
         props.setCurrent(props.post); 
         document.querySelector("#IdeaView").scrollTop = 0;
         history.push("/"+props.post.slug);
         }}>
         {//<a href={link} className="permalink"></a>
         }
         <span className="fadetop"></span><div className="window"><div className="post-meta"><div className="post-type  "><div className="icon"> </div></div> <a className="topic" style={{pointerEvents:"none"}} href={categoryLink}> {categoryName} </a>  <h3>{title}</h3><div  className="engagement"><a href={authorLink}  style={{pointerEvents:"none"}}  title={"Posts by "+author} rel="author">{author}</a><div className="lights"><img  src={lightIcon} alt="Lights Icon"/>{lightsCount}</div></div><div href="#" className="timestamp"> </div>     </div></div><div className="content" dangerouslySetInnerHTML={{__html:excerpt}}></div> {props.children} </div>)

}


export default RelatedIdea;