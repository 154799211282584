import React from 'react';

export default class ActionButton extends React.Component {
  constructor(props) {
    super(props);
  }

   render() {
    let className = "composr-action-button";
    if(typeof(this.props.name) !== "undefined"){
      className += " "+this.props.name
    }
    if(typeof(this.props.className) !== "undefined"){
      className += " "+this.props.className
    }
    
      return (
        <div 
          id={this.props.id}
          style={this.props.style}
          className={className}
          onClick={this.props.action}
          onMouseEnter={function(event){event.currentTarget.classList.add("hover")}}
          onMouseLeave={function(event){event.currentTarget.classList.remove("hover")}}
          onMouseDown={function(event){event.currentTarget.classList.add("active")}}
          onMouseUp={function(event){event.currentTarget.classList.remove("active")}}
          onTouchStart={function(event){event.currentTarget.classList.add("active")}}
          onTouchEnd={function(event){event.currentTarget.classList.remove("active")}}   
         >
           {this.props.children}
        </div>
      )    
   }
}