import './Idea.css';
import React , {useEffect, useState} from 'react';
import Timeline from '../../components/Header/Timeline';
import SmallFooter from '../../components/Footer/SmallFooter.js'
import SideBar from '../Sidebar/Sidebar';
import AdSense from 'react-adsense';
import RelatedIdea from '../../components/RelatedIdea/RelatedIdea.js';
import gsap from 'gsap';
import styled from 'styled-components';

const StyledIdea = styled.div`
  & img{
    width: calc(100% + 32px);
    height: auto;
    margin: 0 -16px;
  }
`;

const StyledRelatedIdea = styled(RelatedIdea)`

& .content{
  max-height: 0;
  overflow: hidden;
  padding: 0;
}

margin-bottom:0;

`;

const StyledFooter = styled(SmallFooter)`
  display: flex;
  text-align:right;
`;

const NextIcon = styled.div`
  position: absolute;
    z-index: 999;
    font-size: 24px;
    padding: 0 8px;
    color: #fff;
    top: 50%;
    right: 0;
    transform: translate(0, -50%) rotate(-90deg);`;

    const PrevIcon = styled.div`
    position: absolute;
      z-index: 999;
      font-size: 24px;
      padding: 0 8px;
      color: #fff;
      top: 50%;
      left: 0;
      transform: translate(0, -50%) rotate(90deg);`;


const RelatedContent = styled.div`
  display:flex;
  flex-direction:column;
  .related-idea{
    width:100%;
    margin:0;
    cursor:pointer;
  }

  @media screen and (min-width: 550px){
    flex-direction:row-reverse;
    & .related-idea{
      width:50%;
      margin:0;
    }
    & .related-idea:first-child{
      width:50%;
      margin:0;

      & .post-meta{ padding-left: 3rem;}
    }
   
    & .related-idea:last-child .post-meta{
      padding-right: 3rem;
      }
  }
`;


const TimelineBox = styled.div`
  padding:8px 0;
  background:#fff;
  margin: 0 0 16px;
  width:100%;

 &.fixed{
    box-sizing: border-box;
    top: 50px;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 9;
    padding: 8px;
    border: 2px solid;
    border-width: 0 2px;
  }

  @media screen and (min-width:550px){
  
  }
`;


function MulticolorIdeaView(props) {

const [nextPost, setNextPost] = useState(null);
const [prevPost, setPrevPost] = useState(null);
const [nextPostId, setNextPostId] = useState(null);
const [prevPostId, setPrevPostId] = useState(null);
const [progress, setProgress] = useState(0);

  const trackProgress = () =>{
    if(document.body.classList.contains("home")){return}
    let wHeight = window.innerHeight;

    let scrollTop = document.querySelector("#IdeaView").scrollTop;
   
    let contentHeight = document.querySelector("#IdeaView").scrollHeight;
    
    let progressRatio = ((scrollTop + wHeight) / contentHeight).toFixed(3);

    if(scrollTop < wHeight){
      let introRatio = ((scrollTop/wHeight)).toFixed(3);

      if(window.introTimeline){
        let ratio = parseFloat(introRatio);
        window.introTimeline.progress(ratio);
      }
      if(window.ideaTimeline){
        let ratio = parseFloat(progressRatio);
      //  console.log(ratio);
        window.ideaTimeline.progress(ratio);
      }
    }
  

    setProgress(progressRatio);

    
  }

  window.timelineTimer = window.timelineTimer ? clearInterval(window.timelineTimer) : false;
  
  window.timelineTimer = setInterval(()=>{
   // trackProgress();
    //window.requestAnimationFrame(timelineTimer);
  },750);

 
useEffect(()=>{

  let slug = props.location.location.pathname.slice(1);
 // console.log("Current Slug", slug);

 if(typeof props.post !== "undefined" && props.post !== null && slug !== props.post.slug){
   //props.setCurrent(null)
  }

  if(props.post == null){
    document.body.classList = "home";
    document.body.style.overflow = "auto";
   // window.introTimeline.progress(0);
  }else{
  
    document.body.style.overflow = "hidden";
    document.body.classList.remove("home");
    document.body.classList.add(props.post._embedded["wp:term"][0][0].link.split("category/")[1].split("/")[0]);

    if(nextPost == null && props.post.next){
      fetch("https://ideasmulticolor.com/wp-json/wp/v2/posts/"+(props.post.next.id)+"?_embed").then((data)=>{return data.json()}).then((data)=>{
        setNextPost(data)
    });
    }else if(props.post.next && props.post.next.id !== nextPost.id || typeof props.post.next == "undefined"){
      setNextPost(null);
    }
      
    if(prevPost == null && props.post.previous){
      fetch("https://ideasmulticolor.com/wp-json/wp/v2/posts/"+(props.post.previous.id)+"?_embed").then((data)=>{return data.json()}).then((data)=>{
        setPrevPost(data)
    });
   }else if(props.post.previous && prevPost && (props.post.previous.id !== prevPost.id) || typeof props.post.previous == "undefined"){
    setPrevPost(null);
  }
    

    const storyElements = document.querySelectorAll("#main-column .author-box, #main-column div > p, #main-column div > h4");

    window.introTimeline = gsap.timeline();
    window.ideaTimeline = gsap.timeline();

    gsap.fromTo(".featured-image i.fa-chevron-down", 2, {y:0}, {y:50, repeat:-1, yoyo:true});

   // window.ideaTimeline.to(".container",1,{opacity:0, onComplete:()=>{alert("Complete")}},"+=1");
    window.ideaTimeline.pause();
    
   // window.introTimeline.to(".container", 0.5, {scale:0.9},"+=10");
   // window.introTimeline.set(".container", {scale:0.9});
    window.introTimeline.to("#post-meta h1", 2, {scale:2, opacity:0});
    window.introTimeline.to("#IdeaView",2,{backgroundSize:"auto 120vh"},"-=2");
    window.introTimeline.to("#post-meta .topic, #post-meta .views, #post-meta .author-box", 1, {opacity:0},"-=2");

    window.introTimeline.to("#side-column .author-image", 0.5, {width:"7rem", height:"7rem", opacity:1});
    window.introTimeline.to("#side-column .author-box", {duration:0.5, opacity:1});
 //   window.introTimeline.to(".container .row", 1, {position:"relative",top:"-200px"},"-=3");
    window.introTimeline.to(".featured-image i.fa-chevron-down",1,{opacity:0},"-=1");
   
   // window.introTimeline.set(".container",  {transform:"none"},"-=1");
   window.introTimeline.set("#timeline-box", {position:"relative",top:"0",onComplete:e=>{
    // document.querySelector("#timeline-box").classList.remove("fixed");
   }},"-=1");
    window.introTimeline.set("#timeline-box", {position:"fixed",left:0, padding:"10px", borderStyle:"solid", borderWidth:"0 2px",top: window.innerWidth > 550 ? "60px" : "50px", width:"100%", zIndex:1,boxSizing:"border-box",onComplete:e=>{
     // document.querySelector("#timeline-box").classList.add("fixed");
    }},"-=0.5");
   
   
   
    window.introTimeline.pause();
   
   
   // window.ideaTimeline.from("#IdeaView .container", 1,{top:"-100px"});
   // window.ideaTimeline.to(".featured-image i", 1,{scale:0, bottom:"-100px"});
    
    // for(let i = 0; i<storyElements.length;i++){
    //   gsap.set("#main-column div > p, #main-column div > h4",{opacity:0})
    //   let tween = gsap.to(storyElements[i], {duration:0.5, opacity:1},"-=1");
    //   window.ideaTimeline.add(tween);
    // }
   
  
  }



}, [props.post]);

let post = props.post;

if(post == null){return null};


let height = window.innerHeight;
let title = post.title.rendered;
     let link = post.link;
     let excerpt = post.excerpt.rendered;
     let author = post._embedded.author[0].name;
     let authorId = post.author;
     let authorLink = post._embedded.author[0].link;
     let image = "";
     if(post._embedded["wp:featuredmedia"]){
        image = post._embedded["wp:featuredmedia"][0].media_details.sizes.full.source_url;
     }
     
    // let categoryColor = post._embedded["wp:term"][0][0].link.split("category/")[1].split("/")[0];
     let categoryName = post._embedded["wp:term"][0][0].name;
    // let categoryLink = post._embedded["wp:term"][0][0].link;

     let lightsCount = post.meta['post_views_count'][0];

     let textarea = document.createElement("textarea");
     textarea.innerHTML = title;
     title = textarea.value;
     textarea.innerHTML = excerpt;
     excerpt = textarea.value;
     textarea.innerHTML = categoryName;
     categoryName = textarea.value;
     textarea.innerHTML = post.content.rendered;
     let content = textarea.value;

   
   

return (
<StyledIdea id="IdeaView" location={props.location} className="single-template" style={{backgroundImage:"url("+image+")"}} onScroll={e=>{
  trackProgress();
}}>



<div className="row"> <div className="one-row">
    <div className="featured-image" style={{height: height+"px"}}>
	    <div id="post-meta">
      <div className="post-type  ">
	   	 <div className="icon"> </div>
	   	 	 </div>
   	 
   	 <div className="topic">
	    <a href={link}>{categoryName}</a>
      </div> 
<h1 style={{color:"#fff", margin:0, fontSize:"1.5rem", fontWeight:700, letterSpacing:"normal"}}>{title}</h1>  
   	 <div className="views" style={{display:"inline-block"}}> 
          {lightsCount} <span>Lights</span>
      </div><div className="author-box" style={{display:"inline-block"}}>
      <a href={authorLink}>
        <div className="author-name">{author}</div>
      </a>
      </div>
     
      </div>
   
   <div onClick={()=>{
       document.querySelector('#idea-start').scrollIntoView({behavior: 'smooth'});
   }} style={{position: "absolute",
    bottom: "100px",
    textAlign: "center",
    width: "100%",
    color: "#fff"}}><i className="fa fa-chevron-down" aria-hidden="true"></i></div>
	</div>
    
</div>
</div>
    <div id="idea-start" style={{position:"relative"}}></div>
    <div style={{background:"#fff"}}>
  <div className="container">  

  <div className="row"  style={window.innerWidth > 550 ? {padding:"16px", boxShadow:"rgb(98 97 97 / 65%) 0px 5px 30px 0px", background:"#fff"} : {background:"#fff"}}>
<SideBar authorId={authorId}/>

  <div id="main-column" className="nine columns">
    <TimelineBox id="timeline-box">
    <Timeline progress={progress}/>
    </TimelineBox>
 
  <div dangerouslySetInnerHTML={{__html:content}}></div>
{/* 
<AdSense.Google
  client='ca-pub-6317861874330385'
  slot='2588595314'
  layout="sub-article-desktop"
  format="auto"
  responsive = "true"
  /> */}

</div>


</div>
</div>
</div>
<RelatedContent>
 
<StyledRelatedIdea post={nextPost} setCurrent={props.setCurrent} style={{paddingRight:"36px;"}}>
<NextIcon><i className="fa fa-chevron-down" aria-hidden="true"></i></NextIcon>
</StyledRelatedIdea>

<StyledRelatedIdea post={prevPost} setCurrent={props.setCurrent} style={{paddingLeft:"36px;"}}>
<PrevIcon><i className="fa fa-chevron-down" aria-hidden="true"></i></PrevIcon>
</StyledRelatedIdea>



</RelatedContent>

<StyledFooter/>
</StyledIdea>);
}

export default MulticolorIdeaView;