import React , {useEffect, useState} from 'react';
import styled from 'styled-components';

const StyledAuthorBox = styled.div`
    display:flex;
    padding:0 0 24px;
    align-items:center;
`;

const StyledImage = styled.img`

`;

function AuthorBox(props) {

    const [authorData, setAuthor] = useState(null);

useEffect(()=>{
    if(authorData === null || authorData.id !== props.userId){
        setAuthor(null);
        fetch("https://ideasmulticolor.com/wp-json/wp/v2/users?_embed&include="+props.userId).then((data)=>{return data.json()}).then((data)=>{
            setAuthor(data[0])
        });
    }

})


let authorName, authorImage, authorDescription = null;

if(authorData){
    authorName = authorData.name;
    authorImage = authorData.avatar_urls['96'];
    authorDescription = authorData.description;
}

if(authorData == null){return null}

return (
    <StyledAuthorBox className="author-box">
        <div className="author-image"><StyledImage src={authorImage} alt="author image"/></div>
        <div className="author-info" style={{padding:'0 8px'}}>
            <div style={{fontWeight:"bold", color:"#434141", fontSize:"16px"}}>{authorName}</div>
            <div>{authorDescription}</div>
        </div>
        </StyledAuthorBox>);
}

export default AuthorBox;