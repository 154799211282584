import React from 'react';
import styled from 'styled-components';

const StyledTimeline = styled.div`
    position: relative;
    height: 3px;
    background: #000;
    width: 100%;
    margin: 0 auto;
    transition: all .25s ease-in-out;

    & .progress{
        display: block !important;
        background: #fff;
        right: 0;
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: 0;
        transform: translateY(3px) scaleX(0.75);
        transform-origin: left;
    }

    & .scrubber{
        width: 12px;
    height: 12px;
    position: absolute;
    left: 100%;
	background:#fff;
	border-style:solid;
	border-width:2px;
	border-radius: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    }
`;


function Timeline(props){

    return (
    <StyledTimeline id="timeline"><span className="progress" style={{transformOrigin: "left center", transform: "translateY(0px) scaleX("+props.progress+")"}}></span><span className="scrubber" style={{left: "calc(100% * "+props.progress+")"}}></span></StyledTimeline>
    );

}



export default Timeline;