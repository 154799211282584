import './App.css';
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, useParams} from 'react-router-dom';

import { TransitionGroup, CSSTransition} from "react-transition-group";

import ActionButton from './components/actionButton.js';
import Header from './components/Header/Header.js';
import Filters from './components/Filters/Filters.js';
import Footer from './components/Footer/Footer.js';
import IdeaPost from './components/IdeaPost/IdeaPost.js';
import IdeaView from './views/Idea/Idea.js';
import getPage from './network/getPage.js';
import AdSense from 'react-adsense';
import styled from 'styled-components';

const privateRoutes = ["/","/nuevo","/popular","/curado"];
const forbiddenRoutes = ["/wp-admin","/analytics"];

const IdeaFeed = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction:column;

  @media screen and (min-width:550px){
   flex-direction:row;
  }
`;


function MulticolorApp() {

  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPost, setCurrent] = useState(null);
  const [filters, setFilters] = useState({order:"desc", order_by:"post_views_count", tags:""});
  

  const addPost = (post)=>{
    let newPosts = posts;
    newPosts.push(post);
    setPosts(newPosts);
  }

  

  useEffect(()=>{

    

   if(page === 1 && posts.length === 0 ){
    if(window.location.pathname == "/nuevo" && posts.length == 0){
      let newFilter = filters;
      newFilter.order_by = "date";
     // newFilter.tags = "";
      setFilters(newFilter);
    }
    if(window.location.pathname == "/curado" || window.location.pathname == "/" && posts.length == 0){
      let newFilter = filters;
      newFilter.tags = "39";
      setFilters(newFilter);
    
    }
    if(window.location.pathname == "/popular" && posts.length == 0){
      let newFilter = filters;
    //  newFilter.tags = "";
      newFilter.order_by = "post_views_count";
      setFilters(newFilter);
    }
     //Replace this for a better regex
     if(window.location.search.includes("?s=")){
      fetch("https://ideasmulticolor.com/wp-json/wp/v2/posts?_embed&search="+window.location.search.slice(3)).then((data)=>{return data.json()}).then((data)=>{
        setPage(0);
        setPosts(data);
       });
    }else if(privateRoutes.includes(window.location.pathname)){
      loadArticle(page);
    }
    
    }
   window.mc = {currentPost, page, posts};
  });

  const loadArticle = (pageNumber) =>{ 
      fetch("https://ideasmulticolor.com/wp-json/wp/v2/posts?_embed&page="+pageNumber+"&status=publish&per_page=9&order="+filters.order+"&orderby="+filters.order_by+(typeof filters.tags !== "undefined" ? "&tags="+filters.tags : '')).then(function(json){
      return  json.json();
      }).then(function(jsonObject){
      //  let newPosts = [...new Set([...posts, ...jsonObject])];
        let newPage = page +1;
        let newPosts = posts.concat(jsonObject);
        setPage(newPage);
        setPosts(newPosts);
        
      })
   }


  let postList = posts.map(function(post,index){
   
    if(post.status !== "publish"){return null}
        return <IdeaPost key={index} post={post} setCurrent={setCurrent}/>
    });

    if(postList.length > 10){
      for(let i=1;i<postList.length%10;i++){

        let GoogleAd = <div className="post one-third column" >

          <div style={{width:"300px",height:'250px'}}>

             {/* <AdSense.Google
        client='ca-pub-6317861874330385'
        slot='2588595314'
        layout="sub-article-desktop"
        format="auto"
        responsive = "true"
        /> */}
          </div>
        
       

        </div>

       // postList.splice(i*10,0,GoogleAd)
      }
    }

    let rows = [];
        
    for(let i = 0; i<=parseInt((postList.length/3)); i++){
        let postIndex = i*3;
        let row = (<Row key={i} className='row'>{postList[postIndex]}{postList[postIndex+1]}{postList[postIndex+2]}</Row>)
        rows.push(row);
    }

    let collapsed = null;
  //  if(currentPost){collapsed = "collapsed"};

  if(!privateRoutes.includes(window.location.pathname) && currentPost == null){
    if(window.location.pathname === "/nosotros"){
      getPage("nosotros", (data)=>{console.log(data)});
      }else{
        fetch("https://ideasmulticolor.com/wp-json/wp/v2/posts?_embed&page=1&status=publish&per_page=9&order=desc&orderby=post_views_count&slug="+window.location.pathname.slice(1)).then((data)=>{return data.json()}).then((data)=>{
          setCurrent(data[0]);
        
       });
      }
  }

  let location = window.location;


   

  return (<Router>
    <div className="MulticolorApp" style={{position : "relative"}}>
      <Header setCurrent={setCurrent} currentPost={currentPost}/>
      <TransitionGroup style={{height:"100%"}}>
        <CSSTransition
          timeout={100}
          classNames='fade'
          key={location}
        >
      <Switch location={location}>
      <Route path="/" render={(location)=>{
    //    console.log("Changed location", location);
        
     

        if(privateRoutes.includes(location.location.pathname)){setCurrent(null)}
        else{
       //   console.log("Checking if location changed with system UI")
          if(location.history.action !== "PUSH" && currentPost && location.location.pathname.slice(1) !== currentPost.slug && location.location.pathname.slice(1) !== (currentPost.slug+"/")){
         //   console.log("Post doesn't match URL");

            //Check if post already is in memory
            let preloadedPost = posts.filter(post=>{
              return post.slug == location.location.pathname.slice(1) ? true : false;
            });

        //    console.log(posts, preloadedPost);

            if(preloadedPost.length == 1){
           //   console.log("Found a previously loaded post");
              setCurrent(preloadedPost[0]);
            }else if(preloadedPost.length == 0){
              setCurrent(null);
            }
          }
        }
       

        if(location.pathname == "/" && currentPost){}
        return <div><IdeaView post={currentPost} setCurrent={setCurrent} location={location} />

        <IdeaFeed className={"container "+ collapsed}>

           <Filters setFilters={setFilters} setPosts={setPosts} loadArticle={loadArticle} setPage={setPage}/>

             {rows}

           <ActionButton style={{marginBottom:"2rem"}}id="load" action={()=>{loadArticle(page)}}><i className="fa fa-spinner fa-pulse"></i><b> + </b>MAS IDEAS<b> + </b><i className="fa fa-spinner fa-pulse"></i></ActionButton>
  
      </IdeaFeed></div>
      }}/>
          <Route path="/" >
            
            
         
          </Route>

          {/* <Route path="/nuevo" render={()=>{
            console.log("Filtrando posts nuevos");
          }}/>

          <Route path="/curado" render={()=>{
            console.log("Filtrando posts nuevos");
          }}/>

          <Route path="/popular" render={()=>{
            console.log("Filtrando posts nuevos");
          }}/> */}
         
            
      </Switch>
      
      </CSSTransition></TransitionGroup>
  
      <Footer/>
    </div>
    </Router>
  );
}

export default MulticolorApp;
