import './Header.css';
import React , {useEffect, useState} from 'react';
import Timeline from './Timeline.js';
import Search from './Search.js';
import {Link} from 'react-router-dom';
import styled from 'styled-components';


const Logo = styled.div`

@media screen and (min-width:550px){
  .fixed &{
    width: 37px !important;
  height: 44px !important; 
  }
  
}

`;

const HeaderContent = styled.div`
@media screen and (min-width:550px){
  .fixed &{
    padding: 0 !important;
  }
  
}
`;

const Title = styled.div`
@media screen and (min-width:550px){
  .fixed &{
    font-size:18px !important;
    flex-basis:100% !important;
  }
  
}
`;



function Header(props) {
  const [isFixed, setFixed] = useState(false);


  

  

  //window.requestAnimationFrame(timelineTimer);

  const checkScroll = event =>{
    if(props.currentPost){
     //trackProgress();
     
      // let RelatedIdea = document.querySelector("#IdeaView .related-idea");
      // if(RelatedIdea){
      //   if(RelatedIdea.getBoundingClientRect().bottom <= window.innerHeight){
      //     RelatedIdea.querySelector(".window").style.paddingTop = "calc(50% + "+(window.innerHeight-RelatedIdea.getBoundingClientRect().bottom)+"px)";

      //     if()
      //    // RelatedIdea.querySelector(".window").style.paddingTop = "calc(54.60 + "+RelatedIdea.getBoundingClientRect().bottom+"px)";
      //   }else{
      //     RelatedIdea.querySelector(".window").style.paddingTop = "54%";
      //   }
      // }

    }else{
      (window.scrollY>= 50) ? setFixed(true) : setFixed(false);
    }
   
    if(document.body.classList.contains("home")){
      (window.scrollY>= 50) ? setFixed(true) : setFixed(false);
    }else if(props.currentPost){
      (document.querySelector("h1").getBoundingClientRect().top < 80) ? setFixed(true) : setFixed(false);
    }
  }

  useEffect(() =>{
    window.addEventListener("scroll", checkScroll);

    if(props.currentPost){
      document.querySelector("#IdeaView").addEventListener("scroll", checkScroll);
      checkScroll();
    }else{
      checkScroll();
    }

    if(document.querySelector("#IdeaView")){
    //  trackProgress();
    }
  });



  let className = "row";
  if(isFixed){className = "row fixed"};

  let postTitle = "MULTI-<br/>COLOR";

  if(props.currentPost && isFixed){
    postTitle =  props.currentPost.title.rendered
  }

  return (
  <header className={className}>
	
	<HeaderContent className="content header-content">
		

<Logo id="logo"> <Link to="/" className="home-link" onClick={(event)=>{props.setCurrent(null);  checkScroll();}}> </Link>
    </Logo>

    <Title className="title" dangerouslySetInnerHTML={{__html:postTitle}}>
     
    </Title>

    <Search />


</HeaderContent>

</header>

  );
}


export default Header;