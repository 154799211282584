import React from 'react';
import AuthorBox from '../../components/AuthorBox';
import styled from 'styled-components';

const StyledColumn = styled.div`
width:100%;
@media screen and (min-width:550px){
    width:12rem;
    float: left;
}
`;

export default function(props){
   
    return <StyledColumn id="side-column">
        <AuthorBox userId={props.authorId}/>
        </StyledColumn>
}