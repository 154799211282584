import './Footer.css';
import React from 'react';
function Footer(props){
    return(<footer {...props}>
    <span className="disclaimer">MULTICOLOR 2020</span>
    {/* <span className="socialmedia"><a href="http://facebook.com/IdeasMulticolor"><i className="fa fa-facebook-square fa-lg"></i></a><a href="http://twitter.com/IdeasMulticolor"><i className="fa fa-twitter-square fa-lg"></i></a><a href="http://instagram.com/ideasmulticolor/"><i className="fa fa-instagram fa-lg"></i></a></span> */}
    <span className="about">
    {/* <a href="http://ideasmulticolor.com/nosotros/">¿QUIENES SOMOS?</a> */}
    </span>

</footer>);
}

export default Footer;